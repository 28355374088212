import React from "react";
import PropTypes from "prop-types";

const AnswerButton = ({ title, description, colorSchema, onClick }) => {
  return (
    <button
      className={` ${
        colorSchema === "yes"
          ? "btn-form-yes"
          : colorSchema === "no"
            ? "btn-form-no"
            : "btn-form-maybe"
      } ${colorSchema === "yes" ? "mb-1 mt-2" : "mb-1"} ml-1 mr-1 p-1`}
      onClick={onClick}
    >
      <div className="d-flex flex-column justify-content-center">
        <h4 className="answer-headline">{title}</h4>
        {!!description && <p className="answer-subline">{description}</p>}
      </div>
    </button>
  );
};

AnswerButton.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  colorSchema: PropTypes.string,
  onClick: PropTypes.string,
};

export default AnswerButton;
