import React, { useState, useEffect } from "react";
import { colors } from "../../app/settings";
import AnswerButton from "./answerButton";

export const questionTypes = [
  "skillLevel",
  "preferredTech",
  "workPlace",
  "beginSoon",
  "salary",
  "firstName",
  "lastName",
  "email",
  "phone",
];

const Question = ({
  questionNumber,
  updateData,
  paginate,
  page,
  maxPageNumber,
  data,
  sendData,
  closeModal,
}) => {
  const withoutInput =
    questionTypes[page] === "skillLevel" ||
    questionTypes[page] === "preferredTech" ||
    questionTypes[page] === "workPlace" ||
    questionTypes[page] === "beginSoon";
  const withoutDescription = questionTypes[page] === "beginSoon";
  const [singleInput, setSingleInput] = useState("");
  const [isNextStepAvailable, setIsNextStepAvailable] = useState(false);
  const [isLastPageReady, setIsLastPageReady] = useState(false);
  const [firstName, setFirstName] = useState(data.firstName);
  const [lastName, setlastName] = useState(data.lastName);
  const [email, setEmail] = useState(data.email);
  const [phone, setPhone] = useState(data.phone);
  const [isDataPolicyChecked, setIsDataPolicyChecked] = useState(false);

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  useEffect(() => {
    setIsNextStepAvailable(page !== 5 || validateEmail(email));
  }, [email, page, phone]);

  useEffect(() => {
    setIsLastPageReady(isDataPolicyChecked);
  }, [isDataPolicyChecked]);

  const handleNext = () => {
    if (page < maxPageNumber - 2) {
      updateData(questionTypes[page], singleInput);
    } else {
      // Reached last page
      updateData("firstName", firstName);
      updateData("lastName", lastName);
      updateData("email", email);
      updateData("phone", phone);
    }
    paginate(1);
  };

  return (
    <>
      <div className="d-flex justify-content-center">
        <h3 className="text-center form-question">{questionNumber.question}</h3>
      </div>
      <div className="d-flex flex-column justify-content-center">
        {page !== maxPageNumber - 2 ? (
          <>
            {withoutInput ? (
              <>
                <AnswerButton
                  title={questionNumber.answers[0]}
                  description={
                    withoutDescription
                      ? null
                      : questionNumber.answerDescriptions[0]
                  }
                  colorSchema="yes"
                  onClick={() =>
                    updateData(questionTypes[page], questionNumber.answers[0])
                  }
                />
                <AnswerButton
                  title={questionNumber.answers[1]}
                  description={
                    withoutDescription
                      ? null
                      : questionNumber.answerDescriptions[1]
                  }
                  colorSchema="no"
                  onClick={() =>
                    updateData(questionTypes[page], questionNumber.answers[1])
                  }
                />
                <AnswerButton
                  title={questionNumber.answers[2]}
                  description={
                    withoutDescription
                      ? null
                      : questionNumber.answerDescriptions[2]
                  }
                  colorSchema="maybe"
                  onClick={() =>
                    updateData(questionTypes[page], questionNumber.answers[2])
                  }
                />
              </>
            ) : (
              <>
                {page === maxPageNumber - 1 ? (
                  <>
                    <ul className="mt-2 form-summary">
                      <li className="row-flex summary-item">
                        <strong style={{ fontFamily: "Avantgarde" }}>
                          Name:
                        </strong>{" "}
                        {data.firstName} {data.lastName}
                      </li>
                      <li className="row-flex summary-item">
                        <strong style={{ fontFamily: "Avantgarde" }}>
                          E-Mail:
                        </strong>{" "}
                        {data.email}
                      </li>
                      <li className="row-flex summary-item">
                        <strong style={{ fontFamily: "Avantgarde" }}>
                          Telefon:
                        </strong>{" "}
                        {data.phone}
                      </li>
                      <li className="flex-row summary-item">
                        <strong style={{ fontFamily: "Avantgarde" }}>
                          Skill-Level:
                        </strong>{" "}
                        {data.skillLevel}
                      </li>
                      <li className="flex-row summary-item">
                        <strong style={{ fontFamily: "Avantgarde" }}>
                          Techstack:
                        </strong>{" "}
                        {data.preferredTech}
                      </li>
                      <li className="flex-row summary-item">
                        <strong style={{ fontFamily: "Avantgarde" }}>
                          Arbeitsort:
                        </strong>{" "}
                        {data.workPlace}
                      </li>
                      <li className="flex-row summary-item">
                        <strong style={{ fontFamily: "Avantgarde" }}>
                          Starttermin:
                        </strong>{" "}
                        {data.beginSoon}
                      </li>
                    </ul>
                    <form className="d-flex flex-column">
                      <label className="form-dsr">
                        <input
                          type="checkbox"
                          className="mr-1"
                          value={isDataPolicyChecked}
                          onChange={() =>
                            setIsDataPolicyChecked(!isDataPolicyChecked)
                          }
                        />
                        Hiermit bestätige ich, dass ich die{" "}
                        <a
                          href="/datenschutz"
                          target="_blank"
                          style={{
                            color: colors.mondayGreen,
                            textDecoration: "underline",
                          }}
                        >
                          Datenschutzerklärung
                        </a>{" "}
                        gelesen habe oder falls nicht, zumindest weiß, dass
                        meine Anfrage (Bewerbung) maximal 3 Monate gespeichert
                        wird und ich wegen eines Stellenangebots kontaktiert
                        werden darf.
                      </label>
                    </form>
                  </>
                ) : (
                  <>
                    {page !== maxPageNumber && (
                      <>
                        <label htmlFor="firstName">
                          <span className="sr-only">Vorname</span>
                        </label>
                        <input
                          id="firstName"
                          type="text"
                          placeholder={
                            data.salary === "" ? "Deine Antwort" : data.salary
                          }
                          className="mt-2 input-form"
                          onChange={(e) => setSingleInput(e.target.value)}
                        />
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </>
        ) : (
          <>
            <label htmlFor="firstName">
              <span className="sr-only">Vorname</span>
            </label>
            <input
              autoComplete="given-name"
              id="firstName"
              type="text"
              placeholder={data.firstName === "" ? "Vorname" : data.firstName}
              className="mt-2 input-form"
              onChange={(e) => setFirstName(e.target.value)}
            />
            <label htmlFor="lastName">
              <span className="sr-only">Nachname</span>
            </label>
            <input
              autoComplete="family-name"
              id="lastName"
              type="text"
              placeholder={data.lastName === "" ? "Nachname" : data.lastName}
              className="mt-1 input-form"
              onChange={(e) => setlastName(e.target.value)}
            />
            <label htmlFor="email">
              <span className="sr-only">E-Mail</span>
            </label>
            <input
              autoComplete="email"
              id="email"
              type="email"
              placeholder={data.email === "" ? "E-Mail" : data.email}
              className="mt-1 input-form"
              onChange={(e) => setEmail(e.target.value)}
            />
            <label htmlFor="phone">
              <span className="sr-only">Telefon</span>
            </label>
            <input
              autoComplete="tel"
              id="phone"
              type="tel"
              placeholder={
                data.phone === "" ? "Telefon (optional)" : data.phone
              }
              className="mt-1 input-form"
              onChange={(e) => setPhone(e.target.value)}
            />
          </>
        )}
      </div>
      <div className="d-flex flex-column">
        {page === maxPageNumber - 1 && (
          <button
            disabled={!isLastPageReady}
            className={`btn text-color-white mt-2 ${
              isLastPageReady ? "bg-monday-skyblue" : "bg-button-disabled"
            }`}
            onClick={() => sendData()}
          >
            Abschicken
          </button>
        )}
        {page !== maxPageNumber - 1 && (
          <>
            {!withoutInput && (
              <button
                disabled={!isNextStepAvailable}
                className={`btn text-color-white mt-2 ${
                  isNextStepAvailable
                    ? "bg-monday-skyblue"
                    : "bg-button-disabled"
                }`}
                onClick={() => {
                  page === maxPageNumber ? closeModal() : handleNext();
                }}
              >
                {page === maxPageNumber ? "Schließen" : "Weiter"}
              </button>
            )}
          </>
        )}
        {page > 0 && page !== maxPageNumber && (
          <button className="btn my-2" onClick={() => paginate(-1)}>
            Zurück
          </button>
        )}
      </div>
    </>
  );
};

export default Question;
