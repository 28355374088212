import React, { useState } from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import ProgressBar from "@ramonak/react-progress-bar";
import { colors } from "../../app/settings";
import closeIcon from "../../assets/images/close-icon.svg";
import Slides from "./slides";

const ApplicantForm = ({ className, formHeadline, formType, jobTitle }) => {
  // Needs to be set to prevent screenreaders read data below the modal
  Modal.setAppElement("body");

  const isBrowser = typeof window !== "undefined";
  const [modalActive, setModalActive] = useState(() =>
    isBrowser ? window.location.href.includes("modalActive") : false
  );
  const [status, setStatus] = useState(0);

  const modalStyles = {
    overlay: {
      zIndex: 200,
      backgroundColor: "rgba(0, 0, 0, 0.8)",
    },
    content: {
      margin: "auto",
      maxWidth: "500px",
      maxHeight: "1000px",
      top: "20px",
      left: "20px",
      right: "20px",
      bottom: "20px",
      WebkitOverflowScrolling: "touch",
      borderRadius: "15px",
      padding: "25px",
    },
  };

  const openModal = () => {
    setModalActive(true);
    document.body.style.overflow = "hidden";
  };

  const closeModal = () => {
    setModalActive(false);
    document.body.style.overflow = "scroll";
  };

  return (
    <div className={className}>
      <button
        style={{ cursor: "pointer" }}
        className="btn text-color-white bg-monday-skyblue"
        onClick={openModal}
      >
        Zur Express-Bewerbung
      </button>
      <Modal
        style={modalStyles}
        isOpen={modalActive}
        onRequestClose={closeModal}
        contentLabel="Bewerbungsformular"
        closeTimeoutMS={300}
      >
        <div className="d-flex flex-column h-100">
          <div className="d-flex flex-row mb-2">
            <a href="/datenschutz" target="_blank">
              <button className="btn">Datenschutz</button>
            </a>
            <button className="ml-auto" onClick={closeModal}>
              <img src={closeIcon} alt="" height="35" />
              <span className="sr-only">Formular schließen</span>
            </button>
          </div>
          <h3 className="form-headline">{formHeadline}</h3>
          <p className="form-subline">Bereit für Dein neues Team?</p>
          <Slides
            jobTitle={jobTitle}
            formType={formType}
            closeModal={closeModal}
            setStatus={setStatus}
          />
          <ProgressBar
            bgColor={colors.mondayGreen}
            completed={status}
            className={"mt-auto"}
          />
        </div>
      </Modal>
    </div>
  );
};

ApplicantForm.defaultProps = {
  formHeadline: "#Keyboardninja",
  formType: "javadev",
  jobTitle: "Bewerbung ohne Stellenanzeige",
};

ApplicantForm.propTypes = {
  className: PropTypes.string,
  formHeadline: PropTypes.string,
  formType: PropTypes.string,
  jobTitle: PropTypes.string,
};

export default ApplicantForm;
