import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import Question from "./question";

const Slides = ({ jobTitle, formType, closeModal, setStatus }) => {
  // ******************************************
  // COMPONENT CONSTANTS AND STATES
  // ******************************************
  const maxPageNumber = 7;
  const [[page, direction], setPage] = useState([0, 0]);
  const [requestStatus, setRequestStatus] = useState(false);
  const [data, setData] = useState({
    formType: formType,
    jobTitle: jobTitle,
    skillLevel: "",
    preferredTech: "",
    workPlace: "",
    beginSoon: "",
    salary: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
  });

  const questions = {
    frontenddev: {
      0: {
        question: "Wie gut schätzt Du Deine Frontend-Skills ein?",
        answers: ["Hacker", "Pro", "Noob"],
        answerDescriptions: [
          "Ich kenne mich sehr gut aus!",
          "Ja, ich kann was, aber das ist ausbaufähig.",
          "Man kann immer was dazu lernen.",
        ],
      },
      1: {
        question: "Was machst Du lieber?",
        answers: ["Scripten", "Stylen", "Beides"],
        answerDescriptions: [
          "JavaScript, TypeScript, React",
          "CSS, SCSS",
          "Mich kann man überall einsetzen.",
        ],
      },
      2: {
        question: "Wo möchtest Du arbeiten?",
        answers: ["Am Hafen", "Am Strand", "Mal hier, mal dort"],
        answerDescriptions: [
          "In unserem Office direkt am Fischmarkt.",
          "Remote, wo immer Du grad bist.",
          "Flexibel, wie die Arbeitszeiten.",
        ],
      },
      3: {
        question: "Wann kannst Du bei Monday anfangen?",
        answers: ["Sofort", "In 1-3 Monaten", "In 4-6 Monaten"],
      },
      4: {
        question: "Wie sieht Deine Gehaltsvorstellung aus?",
      },
      5: {
        question: "Wie können wir Dich erreichen?",
      },
      6: {
        question:
          "Dürfen Deine Antworten so abgeschickt und gespeichert werden?",
      },
      7: {
        question: requestStatus
          ? "Danke für Deine Bewerbung! Wir haben Dir eine E-Mail mit einem Bestätigungslink geschickt. Solltest Du dem Link nicht folgen, werden Deine Daten nach 7 Tagen automatisch gelöscht."
          : "Upps! Da ist etwas schiefgelaufen. Bitte überprüfe Deine Internetverbinung.",
      },
    },
    sysadmin: {
      0: {
        question: "Wie gut kennst Du Dich in Cloud-Technologien aus?",
        answers: ["Hacker", "Pro", "Noob"],
        answerDescriptions: [
          "Ich kenne mich sehr gut aus!",
          "Ja, ich kann was, aber das ist ausbaufähig.",
          "Man kann immer was dazu lernen.",
        ],
      },
      1: {
        question: "Wie stehst Du zu Kubernetes?",
        answers: [
          "Mein täglich Brot",
          "Schon mal mit gearbeitet",
          "Bitte nicht",
        ],
        answerDescriptions: [
          "Ich kenne mich sehr gut aus!",
          "Ja ich kann was, aber das ist ausbaufähig.",
          "Will ich gar nichts mit zu tun haben.",
        ],
      },
      2: {
        question: "Wo möchtest Du arbeiten?",
        answers: ["Am Hafen", "Am Strand", "Mal hier, mal dort"],
        answerDescriptions: [
          "In unserem Office direkt am Fischmarkt.",
          "Remote, wo immer Du grad bist.",
          "Flexibel, wie die Arbeitszeiten.",
        ],
      },
      3: {
        question: "Wann kannst Du bei Monday anfangen?",
        answers: ["Sofort", "In 1-3 Monaten", "In 4-6 Monaten"],
      },
      4: {
        question: "Wie sieht Deine Gehaltsvorstellung aus?",
      },
      5: {
        question: "Wie können wir Dich erreichen?",
      },
      6: {
        question:
          "Dürfen Deine Antworten so abgeschickt und gespeichert werden?",
      },
      7: {
        question: requestStatus
          ? "Danke für Deine Bewerbung! Wir haben Dir eine E-Mail mit einem Bestätigungslink geschickt. Solltest Du dem Link nicht folgen, werden Deine Daten nach 7 Tagen automatisch gelöscht."
          : "Upps! Da ist etwas schiefgelaufen. Bitte überprüfe Deine Internetverbinung.",
      },
    },
    javadev: {
      0: {
        question: "Wie gut schätzt Du Deine Java-Skills ein?",
        answers: ["Hacker", "Pro", "Noob"],
        answerDescriptions: [
          "Ich kenne mich sehr gut aus!",
          "Ja, ich kann was, aber das ist ausbaufähig.",
          "Man kann immer was dazu lernen.",
        ],
      },
      1: {
        question: "Was machst Du lieber?",
        answers: ["Integration", "Web-Entwicklung", "Beides"],
        answerDescriptions: [
          "Backend-Anbindungen und externe Kundensysteme.",
          "APIs mit SpringBoot.",
          "Mich kann man überall einsetzen.",
        ],
      },
      2: {
        question: "Wo möchtest Du arbeiten?",
        answers: ["Am Hafen", "Am Strand", "Mal hier, mal dort"],
        answerDescriptions: [
          "In unserem Office direkt am Fischmarkt.",
          "Remote, wo immer Du grad bist.",
          "Flexibel, wie die Arbeitszeiten.",
        ],
      },
      3: {
        question: "Wann kannst Du bei Monday anfangen?",
        answers: ["Sofort", "In 1-3 Monaten", "In 4-6 Monaten"],
      },
      4: {
        question: "Wie sieht Deine Gehaltsvorstellung aus?",
      },
      5: {
        question: "Wie können wir Dich erreichen?",
      },
      6: {
        question:
          "Dürfen Deine Antworten so abgeschickt und gespeichert werden?",
      },
      7: {
        question: requestStatus
          ? "Danke für Deine Bewerbung! Wir haben Dir eine E-Mail mit einem Bestätigungslink geschickt. Solltest Du dem Link nicht folgen, werden Deine Daten nach 7 Tagen automatisch gelöscht."
          : "Upps! Da ist etwas schiefgelaufen. Bitte überprüfe Deine Internetverbinung.",
      },
    },
    coremediaexperte: {
      0: {
        question: "Wie gut schätzt Du Deine CoreMedia-Skills ein?",
        answers: ["Senior", "Intermediate", "Junior"],
        answerDescriptions: [
          "Ich kenne mich sehr gut aus!",
          "Ja, ich kann was, aber das ist ausbaufähig.",
          "Man kann immer was dazu lernen.",
        ],
      },
      1: {
        question: "Was machst Du am liebsten?",
        answers: ["Studio", "CAE & Headless", "DevOps"],
        answerDescriptions: [
          "Das CoreMedia Studio ist mein zu Hause.",
          "Ich entwickle gerne die Web-App.",
          "Die Infrastruktur ist mein Steckenpferd.",
        ],
      },
      2: {
        question: "Wo möchtest Du arbeiten?",
        answers: ["Am Hafen", "Am Strand", "Mal hier, mal dort"],
        answerDescriptions: [
          "In unserem Office direkt am Fischmarkt.",
          "Remote, wo immer Du grad bist.",
          "Flexibel, wie die Arbeitszeiten.",
        ],
      },
      3: {
        question: "Wann kannst Du bei Monday anfangen?",
        answers: ["Sofort", "In 1-3 Monaten", "In 4-6 Monaten"],
      },
      4: {
        question: "Wie sieht Deine Gehaltsvorstellung aus?",
      },
      5: {
        question: "Wie können wir Dich erreichen?",
      },
      6: {
        question:
          "Dürfen Deine Antworten so abgeschickt und gespeichert werden?",
      },
      7: {
        question: requestStatus
          ? "Danke für Deine Bewerbung! Wir haben Dir eine E-Mail mit einem Bestätigungslink geschickt. Solltest Du dem Link nicht folgen, werden Deine Daten nach 7 Tagen automatisch gelöscht."
          : "Upps! Da ist etwas schiefgelaufen. Bitte überprüfe Deine Internetverbinung.",
      },
    },
    firstspiritexperte: {
      0: {
        question: "Wie gut schätzt Du Deine FirstSpirit-Skills ein?",
        answers: ["Senior", "Intermediate", "Junior"],
        answerDescriptions: [
          "Ich kenne mich sehr gut aus!",
          "Ja, ich kann was, aber das ist ausbaufähig.",
          "Man kann immer was dazu lernen.",
        ],
      },
      1: {
        question: "Was machst Du am liebsten?",
        answers: ["ContentCreator", "Templating", "Caas & SmartSearch"],
        answerDescriptions: [
          "Der FirstSpirit ContentCreator ist mein zu Hause.",
          "Ich entwickle gerne die Templates",
          "Die Module sind mein Steckenpferd.",
        ],
      },
      2: {
        question: "Wo möchtest Du arbeiten?",
        answers: ["Am Hafen", "Am Strand", "Mal hier, mal dort"],
        answerDescriptions: [
          "In unserem Office direkt am Fischmarkt.",
          "Remote, wo immer Du grad bist.",
          "Flexibel, wie die Arbeitszeiten.",
        ],
      },
      3: {
        question: "Wann kannst Du bei Monday anfangen?",
        answers: ["Sofort", "In 1-3 Monaten", "In 4-6 Monaten"],
      },
      4: {
        question: "Wie sieht Deine Gehaltsvorstellung aus?",
      },
      5: {
        question: "Wie können wir Dich erreichen?",
      },
      6: {
        question:
          "Dürfen Deine Antworten so abgeschickt und gespeichert werden?",
      },
      7: {
        question: requestStatus
          ? "Danke für Deine Bewerbung! Wir haben Dir eine E-Mail mit einem Bestätigungslink geschickt. Solltest Du dem Link nicht folgen, werden Deine Daten nach 7 Tagen automatisch gelöscht."
          : "Upps! Da ist etwas schiefgelaufen. Bitte überprüfe Deine Internetverbinung.",
      },
    },
  };

  // ******************************************
  // LIFECYCLE METHODS
  // ******************************************
  useEffect(() => {
    setStatus(Math.floor(page * (100 / maxPageNumber)));
  }, [page, setStatus]);

  // ******************************************
  // PAGINATION AND ANIMATION SETTINGS
  // ******************************************
  const paginate = (newDirection) => {
    const nextPage = page + newDirection;
    if (nextPage < 0 || nextPage > maxPageNumber) return;
    setPage([page + newDirection, newDirection]);
  };

  const updateData = (type, newData) => {
    paginate(1);
    setData((data) => {
      return { ...data, [type]: newData };
    });
  };

  const variants = {
    enter: (direction) => {
      return {
        x: direction > 0 ? 500 : -500,
        opacity: 1,
      };
    },
    center: {
      zIndex: 1,
      x: 0,
      opacity: 1,
    },
    exit: (direction) => {
      return {
        zIndex: 0,
        x: direction < 0 ? 500 : -500,
        opacity: 1,
      };
    },
  };

  // ******************************************
  // API CALL
  // ******************************************
  const sendDataToServer = () => {
    fetch(process.env.MAIL_SERVICE_OPTIN, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        if (res.ok) {
          setRequestStatus(true);
        }
      })
      .catch(() => {
        setRequestStatus(false);
      });
    paginate(1);
  };

  return (
    <>
      <AnimatePresence initial={false} custom={direction} exitBeforeEnter>
        <motion.div
          custom={direction}
          variants={variants}
          initial="enter"
          animate="center"
          exit="exit"
          key={page}
          transition={{
            x: { type: "spring", stiffness: 300, damping: 30 },
            opacity: { duration: 0.2 },
          }}
        >
          <Question
            questionNumber={questions[formType][page]}
            updateData={updateData}
            sendData={sendDataToServer}
            paginate={paginate}
            page={page}
            data={data}
            maxPageNumber={maxPageNumber}
            closeModal={closeModal}
          />
        </motion.div>
      </AnimatePresence>
    </>
  );
};

export default Slides;
